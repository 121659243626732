import React, {useRef} from 'react'
import {createPortal} from 'react-dom'
import Button from './Button'
import DarkModeToggle from 'react-dark-mode-toggle'
import {Link} from 'gatsby'
import ScrollLink from './ScrollLink'
import close from './../images/close.svg'

export interface DrawerProps {
  open: boolean
  onClose: () => void
  darkMode: boolean
  mainPage?: boolean
  onChangeDarkMode: () => void
}

export default function Drawer({open, onClose, darkMode, onChangeDarkMode, mainPage = false}: DrawerProps) {
  const backdrop = useRef<HTMLDivElement|null>()

  if (typeof window === 'undefined') {
    return null
  }

  return createPortal(
    <div
      ref={ref => backdrop.current = ref}
      onClick={(event) => {
        if (open && event.target === backdrop.current) {
          onClose()
        }
      }}
      className={`fixed z-[101] top-0 right-0 bottom-0 left-0 transition-all duration-500 ${
        open 
          ? 'bg-white/50' 
          : 'pointer-events-none'
      }`}
    >
      <div
        className={`absolute top-0 bottom-0 right-0 overflow-y-scroll w-[80vw] max-w-[400px] bg-dark-bg text-light-text transition-all duration-300 ${
          open
            ? `translate-x-0 shadow-lg`
            : `translate-x-[80vw] pointer-events-none`
        }`}
      >
        <div className="flex flex-col space-y-16">
          <div className="flex items-center p-4">
            <div className="h-[40px]">
              {typeof window !== 'undefined' && (
                <DarkModeToggle
                  onChange={onChangeDarkMode}
                  checked={darkMode}
                  size={80}
                />
              )}
            </div>
            <div className="ml-auto">
              <button
                onClick={onClose}
                aria-label="Menü schließen"
              >
                <img src={close} width="37px" height="38px" alt="" aria-label="Menü schließen" />
              </button>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center space-y-2 px-4">
            <ScrollLink
              href="/kontakt"
              enabled={mainPage}
              onClick={onClose}
            >
              <Button outlined aria-label="Scheibe uns!">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <g fill="none" fillRule="evenodd">
                    <polygon points="0 0 18 0 18 18 0 18"/>
                    <path fill="#FFF101" fillRule="nonzero" d="M2.5,2.5 L14.5,2.5 L14.5,11.5 L3.3775,11.5 L2.5,12.3775 L2.5,2.5 M2.5,1 C1.675,1 1.0075,1.675 1.0075,2.5 L1,16 L4,13 L14.5,13 C15.325,13 16,12.325 16,11.5 L16,2.5 C16,1.675 15.325,1 14.5,1 L2.5,1 Z M4,8.5 L10,8.5 L10,10 L4,10 L4,8.5 Z M4,6.25 L13,6.25 L13,7.75 L4,7.75 L4,6.25 Z M4,4 L13,4 L13,5.5 L4,5.5 L4,4 Z"/>
                  </g>
                </svg>
                <span>
                Schreibe uns!
              </span>
              </Button>
            </ScrollLink>
            <p className="text-sm">
              Oder rufe uns an unter <a href="tel:06652 74 77 77" className="text-ci-yellow">06652 / 74 77 77</a>
            </p>
          </div>
          <ul className="flex flex-col w-full">
            <li>
              <ScrollLink
                href="/ueber-uns"
                enabled={mainPage}
                onClick={onClose}
                className="flex border-b border-ci-gray p-4 w-full hover:text-ci-yellow hover:bg-ci-gray"
              >
                Über uns
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                href="/unser-angebot"
                enabled={mainPage}
                onClick={onClose}
                className="flex border-b border-ci-gray p-4 w-full hover:text-ci-yellow hover:bg-ci-gray"
              >
                Unser angebot
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                href="/faq"
                enabled={mainPage}
                onClick={onClose}
                className="flex border-b border-ci-gray p-4 w-full hover:text-ci-yellow hover:bg-ci-gray"
              >
                FAQ
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                href="/kontakt"
                enabled={mainPage}
                onClick={onClose}
                className="flex border-b border-ci-gray p-4 w-full hover:text-ci-yellow hover:bg-ci-gray"
              >
                Kontakt
              </ScrollLink>
            </li>
            <li>
              <Link
                to="/impressum"
                className="flex border-b border-ci-gray p-4 w-full hover:text-ci-yellow hover:bg-ci-gray"
              >
                Impressum
              </Link>
            </li>
            <li>
              <Link
                to="/datenschutz"
                className="flex p-4 w-full hover:text-ci-yellow hover:bg-ci-gray"
              >
                Datenschutz
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>,
    document?.body
  )
}
