import React, {HTMLProps} from 'react'

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  outlined?: boolean
}

export default function Button({children, outlined = false, ...props}: ButtonProps) {
  return (
    // @ts-ignore
    <button
      {...props}
      className={`
        inline-flex items-center space-x-2 rounded-full px-4 py-1 hover:shadow-blur duration-1000 transition-colors transition-shadow disabled:opacity-50
        ${outlined ? 'border border-ci-yellow text-ci-yellow' : 'bg-ci-yellow text-ci-gray'}
        ${props.className || ''}
      `}
    >
      {children}
    </button>
  )
}
